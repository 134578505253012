<template>
  <div>
    <div style="padding: 0 30px">
      <el-form :model="searchForm" inline>
        <el-form-item :label="$t('应用编号')">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('接收手机')">
          <el-input
            v-model.trim="searchForm.mobile"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.mobile = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('接收状态')">
          <el-select
            v-model="searchForm.reportStatus"
            :placeholder="$t('全部状态')"
            filterable
            clearable
            @clear="searchForm.reportStatus = null"
            style="width: 200px"
          >
            <el-option
              v-for="(item, key) in dic.reportStatus"
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('签名')">
          <el-input
            v-model.trim="searchForm.signature"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.signature = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('内容')">
          <el-input
            v-model.trim="searchForm.msgContent"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.msgContent = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('发送状态')">
          <el-select
            v-model="searchForm.sendState"
            :placeholder="$t('全部状态')"
            filterable
            clearable
            @clear="searchForm.sendState = null"
            style="width: 200px"
          >
            <el-option
              v-for="(item, key) in dic.sendState"
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('响应代码')">
          <el-input
            v-model.trim="searchForm.reportDesc"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.reportDesc = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('开始时间')">
          <el-date-picker
            v-model="searchForm.startTime"
            type="datetime"
            :placeholder="$t('开始时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <el-form-item :label="$t('结束时间')">
          <el-date-picker
            v-model="searchForm.endTime"
            type="datetime"
            :placeholder="$t('结束时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("搜索") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- </el-card> -->

    <el-card shadow="hover">
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        height="var(--height)"
        class="elTable"
        v-loading="loading"
      >
        <template #empty>
          <el-empty description="" />
        </template>
        <el-table-column prop="appId" :label="$t('应用编号')" align="center" />
        <el-table-column prop="mobile" :label="$t('手机号')" align="center">
          <template #default="scope">
            <div @click="getMobile(scope.$index)" style="cursor: pointer">
              {{ scope.row.mobile }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="attribution"
          :label="$t('归属地')"
          align="center"
        >
          <template #default="scope">
            {{ idToName(scope.row.attribution, dic.province) }}
          </template>
        </el-table-column>
        <el-table-column prop="operator" :label="$t('运营商')" align="center">
          <template #default="scope">
            {{ idToName(scope.row.operator, dic.operator) }}
          </template>
        </el-table-column>
        <el-table-column prop="extendCode" :label="$t('子码')" align="center" />
        <el-table-column
          prop="chargeCount"
          :label="$t('计费条数')"
          align="center"
        >
          <template #default="scope">
            <div style="font-weight: 700">
              <span style="color: #67c23a">{{ scope.row.successCount }}</span>
              /
              <span
                :style="{
                  color:
                    scope.row.successCount === scope.row.chargeCount
                      ? '#67c23a'
                      : '',
                }"
                >{{ scope.row.chargeCount }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sendState"
          :label="$t('发送状态')"
          align="center"
        >
          <template #default="scope">
            {{ idToName(scope.row.sendState, dic.sendState) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="reportStatus"
          :label="$t('接收状态')"
          align="center"
        >
          <template #default="scope">
            <el-tag
              type="success"
              effect="dark"
              v-if="scope.row.reportStatus === 10"
              >{{ idToName(scope.row.reportStatus, dic.reportStatus) }}</el-tag
            >
            <el-tag
              type="danger"
              effect="dark"
              v-if="scope.row.reportStatus === 255"
              >{{ idToName(scope.row.reportStatus, dic.reportStatus) }}</el-tag
            >
            <el-tag
              type="warning"
              effect="dark"
              v-if="scope.row.reportStatus === 20"
              >{{ idToName(scope.row.reportStatus, dic.reportStatus) }}</el-tag
            >
            <el-tag
              type="info"
              effect="dark"
              v-if="scope.row.reportStatus === 0"
              >未知</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="reportDesc"
          :label="$t('响应代码')"
          align="center"
        >
          <template #default="scope">
            <div @click="copy(scope.row.reportDesc)">
              <el-tooltip
                v-if="scope.row.reportDesc"
                effect="dark"
                :content="responseCode_content(scope.row.reportDesc)"
                placement="top"
                raw-content
              >
                <div style="cursor: copy">
                  {{
                    scope.row.reportDesc.split(",").length > 1
                      ? scope.row.reportDesc.split(",")[0] + "..."
                      : scope.row.reportDesc.split(",")[0] +
                        "&nbsp;&nbsp;&nbsp;"
                  }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="msgContent"
          :label="$t('发送内容')"
          align="center"
          width="300"
        >
          <template #default="scope">
            <div style="text-align: left">
              {{ scope.row.msgContent }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          :label="$t('创建时间')"
          align="center"
        >
          <template #default="scope">
            {{ dayjs(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="submitedAt" label="提交时间" align="center">
          <template #default="scope">
            <div v-if="scope.row.submitedAt">
              {{ dayjs(scope.row.submitedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="reportedAt"
          :label="$t('回执时间')"
          align="center"
        >
          <template #default="scope">
            <div v-if="scope.row.reportedAt">
              {{ dayjs(scope.row.reportedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "sendDetail",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref, onMounted, onActivated } from "vue";
// import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { getSendDetails } from "@/http/api/sendDetail";
import dic from "@/utils/dictionary";
import { idToName, onCopy, dayjs, diffTime, encryptPhone } from "@/utils/tool";
// import md5 from "js-md5";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

onBeforeMount(() => {
  query();
});
const loading = ref(false);
const tableData = ref([]);
let total = ref(0);
// 查询表单
const searchForm = reactive({
  mobile: "",
  appId: "",
  // msgId: "",
  sendState: null,
  reportStatus: null,
  signature: "",
  msgContent: "",
  reportDesc: "",
  startTime: null,
  endTime: null,
  page: 1,
  pageSize: 100,
});
const query = async () => {
  loading.value = true;
  let obj = { ...searchForm };

  if (!obj.startTime && obj.endTime) {
    obj.startTime = dayjs(obj.endTime).startOf("date").format();
  }
  obj.startTime ||= dayjs().startOf("date").format();
  obj.endTime ||= dayjs().format();
  if (diffTime(obj.startTime, obj.endTime, 7)) {
    // obj.groupId = obj.groupId * 1;
    const res = await getSendDetails(obj);
    if (res.code === 0) {
      tableData.value = [];
      total.value = res.data.total;
      let list = res.data.list || [];
      for (let i = 0; i < list.length; i++) {
        let mobile2 = list[i].mobile;
        list[i].mobile = encryptPhone(list[i].mobile);
        tableData.value.push({
          ...list[i],
          mobile2,
        });
      }
    }
  }
  loading.value = false;
};
const getMobile = (index) => {
  tableData.value[index].mobile = tableData.value[index].mobile2;
};

// 处理响应代码文本
const responseCode_content = (txt) => {
  let arr = txt.split(",");
  let str = "";
  arr.forEach((item) => {
    str += `${item}<br>`;
  });
  return str;
};
const copy = (txt) => {
  let str = "";
  txt.split(",").forEach((item) => {
    str += `${item}\n`;
  });
  onCopy(str);
  ElMessage({
    type: "success",
    message: t("复制成功"),
  });
};
</script>
      
<style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 330px);
}

::v-deep(label) {
  margin-bottom: 0;
}
// :deep(.el-pager li:last-child) {
//   display: none;
// }
</style>
<style lang="scss">
</style>
      